import React, { useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, useFormik } from 'formik';
import { NavLink } from "react-router-dom";
import { signUpSchema } from "./Signupschema";
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isPossiblePhoneNumber }  from 'react-phone-number-input'

import map from "../images/contact-map.webp";

import './contactbody.css';

const initialValues = {
    name: "",
    company: "",
    subject: "",
    country: "",
    email: "",
    // phone: "",
    message: "",
    agree: false
}


const Contactbody = () => {

    
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    
    const rotateicon = () => {
        const el = document.querySelector(".country-box");
        el.classList.toggle("select");
      }

      let countries = [
        { name: 'Afghanistan', code: 'AF' },
        { name: 'Åland Islands', code: 'AX' },
        { name: 'Albania', code: 'AL' },
        { name: 'Algeria', code: 'DZ' },
        { name: 'American Samoa', code: 'AS' },
        { name: 'AndorrA', code: 'AD' },
        { name: 'Angola', code: 'AO' },
        { name: 'Anguilla', code: 'AI' },
        { name: 'Antarctica', code: 'AQ' },
        { name: 'Antigua and Barbuda', code: 'AG' },
        { name: 'Argentina', code: 'AR' },
        { name: 'Armenia', code: 'AM' },
        { name: 'Aruba', code: 'AW' },
        { name: 'Australia', code: 'AU' },
        { name: 'Austria', code: 'AT' },
        { name: 'Azerbaijan', code: 'AZ' },
        { name: 'Bahamas', code: 'BS' },
        { name: 'Bahrain', code: 'BH' },
        { name: 'Bangladesh', code: 'BD' },
        { name: 'Barbados', code: 'BB' },
        { name: 'Belarus', code: 'BY' },
        { name: 'Belgium', code: 'BE' },
        { name: 'Belize', code: 'BZ' },
        { name: 'Benin', code: 'BJ' },
        { name: 'Bermuda', code: 'BM' },
        { name: 'Bhutan', code: 'BT' },
        { name: 'Bolivia', code: 'BO' },
        { name: 'Bosnia and Herzegovina', code: 'BA' },
        { name: 'Botswana', code: 'BW' },
        { name: 'Bouvet Island', code: 'BV' },
        { name: 'Brazil', code: 'BR' },
        { name: 'British Indian Ocean Territory', code: 'IO' },
        { name: 'Brunei Darussalam', code: 'BN' },
        { name: 'Bulgaria', code: 'BG' },
        { name: 'Burkina Faso', code: 'BF' },
        { name: 'Burundi', code: 'BI' },
        { name: 'Cambodia', code: 'KH' },
        { name: 'Cameroon', code: 'CM' },
        { name: 'Canada', code: 'CA' },
        { name: 'Cape Verde', code: 'CV' },
        { name: 'Cayman Islands', code: 'KY' },
        { name: 'Central African Republic', code: 'CF' },
        { name: 'Chad', code: 'TD' },
        { name: 'Chile', code: 'CL' },
        { name: 'China', code: 'CN' },
        { name: 'Christmas Island', code: 'CX' },
        { name: 'Cocos (Keeling) Islands', code: 'CC' },
        { name: 'Colombia', code: 'CO' },
        { name: 'Comoros', code: 'KM' },
        { name: 'Congo', code: 'CG' },
        { name: 'Congo, The Democratic Republic of the', code: 'CD' },
        { name: 'Cook Islands', code: 'CK' },
        { name: 'Costa Rica', code: 'CR' },
        { name: 'Cote D\'Ivoire', code: 'CI' },
        { name: 'Croatia', code: 'HR' },
        { name: 'Cuba', code: 'CU' },
        { name: 'Cyprus', code: 'CY' },
        { name: 'Czech Republic', code: 'CZ' },
        { name: 'Denmark', code: 'DK' },
        { name: 'Djibouti', code: 'DJ' },
        { name: 'Dominica', code: 'DM' },
        { name: 'Dominican Republic', code: 'DO' },
        { name: 'Ecuador', code: 'EC' },
        { name: 'Egypt', code: 'EG' },
        { name: 'El Salvador', code: 'SV' },
        { name: 'Equatorial Guinea', code: 'GQ' },
        { name: 'Eritrea', code: 'ER' },
        { name: 'Estonia', code: 'EE' },
        { name: 'Ethiopia', code: 'ET' },
        { name: 'Falkland Islands (Malvinas)', code: 'FK' },
        { name: 'Faroe Islands', code: 'FO' },
        { name: 'Fiji', code: 'FJ' },
        { name: 'Finland', code: 'FI' },
        { name: 'France', code: 'FR' },
        { name: 'French Guiana', code: 'GF' },
        { name: 'French Polynesia', code: 'PF' },
        { name: 'French Southern Territories', code: 'TF' },
        { name: 'Gabon', code: 'GA' },
        { name: 'Gambia', code: 'GM' },
        { name: 'Georgia', code: 'GE' },
        { name: 'Germany', code: 'DE' },
        { name: 'Ghana', code: 'GH' },
        { name: 'Gibraltar', code: 'GI' },
        { name: 'Greece', code: 'GR' },
        { name: 'Greenland', code: 'GL' },
        { name: 'Grenada', code: 'GD' },
        { name: 'Guadeloupe', code: 'GP' },
        { name: 'Guam', code: 'GU' },
        { name: 'Guatemala', code: 'GT' },
        { name: 'Guernsey', code: 'GG' },
        { name: 'Guinea', code: 'GN' },
        { name: 'Guinea-Bissau', code: 'GW' },
        { name: 'Guyana', code: 'GY' },
        { name: 'Haiti', code: 'HT' },
        { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
        { name: 'Holy See (Vatican City State)', code: 'VA' },
        { name: 'Honduras', code: 'HN' },
        { name: 'Hong Kong', code: 'HK' },
        { name: 'Hungary', code: 'HU' },
        { name: 'Iceland', code: 'IS' },
        { name: 'India', code: 'IN' },
        { name: 'Indonesia', code: 'ID' },
        { name: 'Iran, Islamic Republic Of', code: 'IR' },
        { name: 'Iraq', code: 'IQ' },
        { name: 'Ireland', code: 'IE' },
        { name: 'Isle of Man', code: 'IM' },
        { name: 'Israel', code: 'IL' },
        { name: 'Italy', code: 'IT' },
        { name: 'Jamaica', code: 'JM' },
        { name: 'Japan', code: 'JP' },
        { name: 'Jersey', code: 'JE' },
        { name: 'Jordan', code: 'JO' },
        { name: 'Kazakhstan', code: 'KZ' },
        { name: 'Kenya', code: 'KE' },
        { name: 'Kiribati', code: 'KI' },
        { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
        { name: 'Korea, Republic of', code: 'KR' },
        { name: 'Kuwait', code: 'KW' },
        { name: 'Kyrgyzstan', code: 'KG' },
        { name: 'Lao People\'S Democratic Republic', code: 'LA' },
        { name: 'Latvia', code: 'LV' },
        { name: 'Lebanon', code: 'LB' },
        { name: 'Lesotho', code: 'LS' },
        { name: 'Liberia', code: 'LR' },
        { name: 'Libyan Arab Jamahiriya', code: 'LY' },
        { name: 'Liechtenstein', code: 'LI' },
        { name: 'Lithuania', code: 'LT' },
        { name: 'Luxembourg', code: 'LU' },
        { name: 'Macao', code: 'MO' },
        { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
        { name: 'Madagascar', code: 'MG' },
        { name: 'Malawi', code: 'MW' },
        { name: 'Malaysia', code: 'MY' },
        { name: 'Maldives', code: 'MV' },
        { name: 'Mali', code: 'ML' },
        { name: 'Malta', code: 'MT' },
        { name: 'Marshall Islands', code: 'MH' },
        { name: 'Martinique', code: 'MQ' },
        { name: 'Mauritania', code: 'MR' },
        { name: 'Mauritius', code: 'MU' },
        { name: 'Mayotte', code: 'YT' },
        { name: 'Mexico', code: 'MX' },
        { name: 'Micronesia, Federated States of', code: 'FM' },
        { name: 'Moldova, Republic of', code: 'MD' },
        { name: 'Monaco', code: 'MC' },
        { name: 'Mongolia', code: 'MN' },
        { name: 'Montserrat', code: 'MS' },
        { name: 'Morocco', code: 'MA' },
        { name: 'Mozambique', code: 'MZ' },
        { name: 'Myanmar', code: 'MM' },
        { name: 'Namibia', code: 'NA' },
        { name: 'Nauru', code: 'NR' },
        { name: 'Nepal', code: 'NP' },
        { name: 'Netherlands', code: 'NL' },
        { name: 'Netherlands Antilles', code: 'AN' },
        { name: 'New Caledonia', code: 'NC' },
        { name: 'New Zealand', code: 'NZ' },
        { name: 'Nicaragua', code: 'NI' },
        { name: 'Niger', code: 'NE' },
        { name: 'Nigeria', code: 'NG' },
        { name: 'Niue', code: 'NU' },
        { name: 'Norfolk Island', code: 'NF' },
        { name: 'Northern Mariana Islands', code: 'MP' },
        { name: 'Norway', code: 'NO' },
        { name: 'Oman', code: 'OM' },
        { name: 'Pakistan', code: 'PK' },
        { name: 'Palau', code: 'PW' },
        { name: 'Palestinian Territory, Occupied', code: 'PS' },
        { name: 'Panama', code: 'PA' },
        { name: 'Papua New Guinea', code: 'PG' },
        { name: 'Paraguay', code: 'PY' },
        { name: 'Peru', code: 'PE' },
        { name: 'Philippines', code: 'PH' },
        { name: 'Pitcairn', code: 'PN' },
        { name: 'Poland', code: 'PL' },
        { name: 'Portugal', code: 'PT' },
        { name: 'Puerto Rico', code: 'PR' },
        { name: 'Qatar', code: 'QA' },
        { name: 'Reunion', code: 'RE' },
        { name: 'Romania', code: 'RO' },
        { name: 'Russian Federation', code: 'RU' },
        { name: 'RWANDA', code: 'RW' },
        { name: 'Saint Helena', code: 'SH' },
        { name: 'Saint Kitts and Nevis', code: 'KN' },
        { name: 'Saint Lucia', code: 'LC' },
        { name: 'Saint Pierre and Miquelon', code: 'PM' },
        { name: 'Saint Vincent and the Grenadines', code: 'VC' },
        { name: 'Samoa', code: 'WS' },
        { name: 'San Marino', code: 'SM' },
        { name: 'Sao Tome and Principe', code: 'ST' },
        { name: 'Saudi Arabia', code: 'SA' },
        { name: 'Senegal', code: 'SN' },
        { name: 'Serbia and Montenegro', code: 'CS' },
        { name: 'Seychelles', code: 'SC' },
        { name: 'Sierra Leone', code: 'SL' },
        { name: 'Singapore', code: 'SG' },
        { name: 'Slovakia', code: 'SK' },
        { name: 'Slovenia', code: 'SI' },
        { name: 'Solomon Islands', code: 'SB' },
        { name: 'Somalia', code: 'SO' },
        { name: 'South Africa', code: 'ZA' },
        { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
        { name: 'Spain', code: 'ES' },
        { name: 'Sri Lanka', code: 'LK' },
        { name: 'Sudan', code: 'SD' },
        { name: 'Suriname', code: 'SR' },
        { name: 'Svalbard and Jan Mayen', code: 'SJ' },
        { name: 'Swaziland', code: 'SZ' },
        { name: 'Sweden', code: 'SE' },
        { name: 'Switzerland', code: 'CH' },
        { name: 'Syrian Arab Republic', code: 'SY' },
        { name: 'Taiwan, Province of China', code: 'TW' },
        { name: 'Tajikistan', code: 'TJ' },
        { name: 'Tanzania, United Republic of', code: 'TZ' },
        { name: 'Thailand', code: 'TH' },
        { name: 'Timor-Leste', code: 'TL' },
        { name: 'Togo', code: 'TG' },
        { name: 'Tokelau', code: 'TK' },
        { name: 'Tonga', code: 'TO' },
        { name: 'Trinidad and Tobago', code: 'TT' },
        { name: 'Tunisia', code: 'TN' },
        { name: 'Turkey', code: 'TR' },
        { name: 'Turkmenistan', code: 'TM' },
        { name: 'Turks and Caicos Islands', code: 'TC' },
        { name: 'Tuvalu', code: 'TV' },
        { name: 'Uganda', code: 'UG' },
        { name: 'Ukraine', code: 'UA' },
        { name: 'United Arab Emirates', code: 'AE' },
        { name: 'United Kingdom', code: 'GB' },
        { name: 'United States', code: 'US' },
        { name: 'United States Minor Outlying Islands', code: 'UM' },
        { name: 'Uruguay', code: 'UY' },
        { name: 'Uzbekistan', code: 'UZ' },
        { name: 'Vanuatu', code: 'VU' },
        { name: 'Venezuela', code: 'VE' },
        { name: 'Viet Nam', code: 'VN' },
        { name: 'Virgin Islands, British', code: 'VG' },
        { name: 'Virgin Islands, U.S.', code: 'VI' },
        { name: 'Wallis and Futuna', code: 'WF' },
        { name: 'Western Sahara', code: 'EH' },
        { name: 'Yemen', code: 'YE' },
        { name: 'Zambia', code: 'ZM' },
        { name: 'Zimbabwe', code: 'ZW' }
      ];

    const [hide, setHide] = useState(false);
    const [modalShow, setModalShow] = useState();
    const [response, setResponse] = useState();
    const [phone, setphone] = useState();
    const [phoneerrmsg, setphoneerrmsg] = useState();
    
    
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            validationSchema: signUpSchema,
            onSubmit: (values, action) => {
              
                if(formatPhoneNumber(phone) === "") {
                    setphoneerrmsg("Please enter your Phone Number");
                } else {

                    setphoneerrmsg();

                    const formData = new FormData();
                    formData.append("name", values.name);
                    formData.append("company", values.company);
                    formData.append("subject", values.subject);
                    formData.append("country", values.country);
                    formData.append("email", values.email);
                    formData.append("phone",  formatPhoneNumberIntl(phone));
                    formData.append("message", values.message);

                    setHide(true);
                    // https://ps-blog.oneweb.agency/wp-json/mail/sendmail
                    // https://blog.preferred-square.com/wp-json/mail/sendmail
                    fetch('https://sandboxmachine.com/ps/wp-json/mail/sendmail', {
                        method: 'POST',
                        mode: 'cors',
                        // headers: {
                        // 'Content-Type': 'application/json',
                        // },
                        
                        // body: JSON.stringify({
                        //     name: values.name,
                        //     company: values.company,
                        //     subject: values.subject,
                        //     country: values.country,
                        //     email: values.email,
                        //     phone: formatPhoneNumberIntl(phone),
                        //     message: values.message,
                        // }),

                        body:formData,
                    })
                .then(res => res.json())
                .then(data => {
                // enter you logic when the fetch is successful
                    setHide(false);
                    setModalShow(true);
                  console.log(data)
                  setResponse(data.message);
                  setTimeout(()=>{
                    setResponse("");
                  }, 2000);
        
                })
            
                // .catch(error => {
                //     // enter your logic for when there is an error (ex. error toast)
                //     console.log(error.message);
                // }) 

                setphone();       
                action.resetForm();
              }
            }
        });

 
    const [forms, setForm] = useState("form1");

    return (
        <>
            <section className='contactbody-section' >
                <Container>
                    <div  className='map-wrapper'>
                        <div className="h1">
                           <span>
                           <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_83_6" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                            <rect width="32" height="32" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_83_6)">
                            <path d="M16 29.8833C13.3416 29.8833 11.1722 29.4621 9.49202 28.6197C7.8118 27.7774 6.97169 26.69 6.97169 25.3573C6.97169 24.7442 7.16602 24.174 7.55469 23.6467C7.94335 23.1193 8.48769 22.6598 9.18769 22.268L11.503 24.5377C11.2604 24.6319 10.9998 24.7439 10.7214 24.8737C10.4429 25.0032 10.2184 25.1591 10.0477 25.3413C10.3755 25.7436 11.1316 26.0956 12.316 26.3973C13.5005 26.6991 14.7317 26.85 16.0097 26.85C17.2875 26.85 18.5215 26.6991 19.7117 26.3973C20.9019 26.0956 21.6599 25.7436 21.9857 25.3413C21.7928 25.1369 21.5502 24.9699 21.258 24.8403C20.9658 24.7106 20.6899 24.5986 20.4304 24.5043L22.7204 22.2013C23.4648 22.61 24.0356 23.0807 24.4327 23.6133C24.8298 24.146 25.0284 24.7268 25.0284 25.3557C25.0284 26.6888 24.1882 27.7767 22.508 28.6193C20.8276 29.462 18.6582 29.8833 16 29.8833ZM16.008 25.6043C12.7836 23.2386 10.3768 20.941 8.78769 18.7117C7.19835 16.4823 6.40369 14.2984 6.40369 12.16C6.40369 10.5504 6.69524 9.13599 7.27835 7.91666C7.86146 6.69733 8.61035 5.67744 9.52502 4.85699C10.4397 4.03655 11.4687 3.42122 12.612 3.01099C13.7551 2.60077 14.8866 2.39566 16.0064 2.39566C17.1261 2.39566 18.2574 2.60077 19.4 3.01099C20.5427 3.42122 21.571 4.03655 22.485 4.85699C23.399 5.67744 24.1475 6.69733 24.7304 7.91666C25.313 9.13599 25.6044 10.5504 25.6044 12.16C25.6044 14.2984 24.8104 16.4823 23.2224 18.7117C21.6344 20.941 19.2296 23.2386 16.008 25.6043ZM16.005 14.6987C16.7617 14.6987 17.409 14.4304 17.947 13.894C18.4852 13.3578 18.7544 12.7131 18.7544 11.96C18.7544 11.2002 18.4855 10.5526 17.9477 10.017C17.4099 9.48144 16.7634 9.21366 16.008 9.21366C15.2505 9.21366 14.602 9.48144 14.0627 10.017C13.5234 10.5526 13.2537 11.1989 13.2537 11.956C13.2537 12.7133 13.523 13.3598 14.0617 13.8953C14.6006 14.4309 15.2484 14.6987 16.005 14.6987Z" fill="#F11576"/>
                            </g>
                            </svg>
                           </span>
                           <span>Our Presence</span>
                        </div>

                        <img src={map} alt='' />
                    </div>

                </Container>
                <Container>
                    <Row className='w-100 mx-auto justify-content-between'>
                        <Col className='col-md-3'>

                            <div className='content-box'>
                                    <div className="subhead">Contact Us</div>
                                    <div className="title">Type of enquiry*</div>
                                 
                                    <div className='switch-btns py-3 text-dark gap-2' >
                                        <div className='btn-box'><input type="radio" value="form1" onChange={() => { setForm('form1') }} name="form" checked={forms === 'form1' ? "checked" : ""}  /> Client-service enquiries</div>
                                        <div className='btn-box'><input type="radio" value="form2" onChange={() => { setForm('form2') }} name="form" checked={forms === 'form2' ? "checked" : ""} /> Recruiting information</div>
                                    </div>

                            </div>

                         </Col>
                    <Col className='col-md-8'>

                        <div className='content-box margintop'>
                            {/* <h4>Type of enquiry*</h4>

                            <div className='d-flex py-3 text-dark gap-2 justify-content-start' >
                                <div><input type="radio" value="form1" onChange={() => { setForm('form1') }} name="form" checked={forms === 'form1' ? "checked" : ""}  /> Client-service enquiries</div>
                                <div><input type="radio" value="form2" onChange={() => { setForm('form2') }} name="form" checked={forms === 'form2' ? "checked" : ""} /> Recruiting information</div>
                            </div> */}

                            <div className='form-box'>
                                {/* <div className={forms === 'form1' ? "form1 d-block" : "form1 d-none"}> */}
                                    <Formik>
                                        {(control) => (
                                            <Form onSubmit={handleSubmit}>

                                                <Row>
                                                    <Col className='col-md-6'>
                                                        <div className='mb-3'>
                                                            {/* <label htmlFor="name" className="">Name*</label> */}
                                                            <Field type="text" name="name" placeholder='Name*' aria-labelledby="name" value={values.name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur} />
                                                            {/* <ErrorMessage name="name" className="" component="div" /> */}
                                                            <div className='errormsg'> {errors.name && touched.name ? (
                                                                <p className="form-error">{errors.name}</p>
                                                            ) : null}</div>
                                                        </div>
                                                    </Col>
                                                    <Col className='col-md-6'>
                                                        <div className='mb-3'>
                                                            {/* <label htmlFor="phone" className="">Phone*</label> */}
                                                
                                                            <PhoneInput placeholder="Phone*" name="phone"  aria-labelledby="phone" value={phone}                                                         
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            defaultCountry="IN"
                                                            onChange={setphone}
                                                                rules={{ required: true, validate: isPossiblePhoneNumber }}
                                                                /> 

                                                            <div className='errormsg'> {phoneerrmsg ? (
                                                                <p className="form-error">{phoneerrmsg}</p>
                                                            ) : null}</div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='col-md-6'>
                                                        <div className='mb-3'>
                                                            {/* <label htmlFor="email" className="">Email*</label> */}
                                                            <Field type="email" name="email" placeholder='Email*' aria-labelledby="email" value={values.email}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur} />
                                                            {/* <ErrorMessage name="email" component="div" /> */}
                                                            <div className='errormsg'> {errors.email && touched.email ? (
                                                                <p className="form-error">{errors.email}</p>
                                                            ) : null}</div>
                                                        </div>
                                                    </Col>
                                                    <Col className='col-md-6'>
                                                        <div className='mb-3'>
                                                            {/* <label htmlFor="company" className="">{forms === 'form1' ? "Organisation*" : "Company / College Name*"} </label> */}
                                                            <Field type="text" name="company" placeholder={forms === 'form1' ? 'Organization*' : "Organisation / College Name*"} aria-labelledby="company" value={values.company}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur} />
                                                            {/* <ErrorMessage name="company" className="" component="div" /> */}
                                                            <div className='errormsg'> {errors.company && touched.company ? (
                                                                <p className="form-error">{errors.company}</p>
                                                            ) : null}</div>
                                                        </div>

                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='col-md-6'>
                                                        <div className='mb-3'>
                                                            {/* <label htmlFor="subject" className="">Subject*</label> */}
                                                            <Field type="text" name="subject" placeholder='Subject*' aria-labelledby="subject" value={values.subject}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur} />
                                                            {/* <ErrorMessage name="subject" className="" component="div" /> */}
                                                            <div className='errormsg'> {errors.subject && touched.subject ? (
                                                                <p className="form-error">{errors.subject}</p>
                                                            ) : null}</div>
                                                        </div>

                                                    </Col>
                                                    <Col className='col-md-6'>
                                                        <div className='mb-3 country-box'>
                                                            {/* <label htmlFor="country" className="">Country*</label> */}
                                                            {/* <Field type="text" name="country" placeholder='' aria-labelledby="country" value={values.country}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur} /> */}

                                                            <select name="country" className="form-control" onClick={() => rotateicon()} value={values.country}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}>
                                                            <option value="0" selected className='country'>Country*</option>
                                                            {countries.map((country) => {
                                                                return <option key={country.code} value={country.name}>{country.name} ({country.code})</option>
                                                            })
                                                            }
                                                            </select>
                                                            
                                                            {/* <ErrorMessage name="country" className="" component="div" /> */}
                                                            <div className='errormsg'> {errors.country && touched.country ? (
                                                                <p className="form-error">{errors.country}</p>
                                                            ) : null}</div>
                                                        </div>

                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='col-md-12'>
                                                        <div className='mb-3'>
                                                            {/* <label htmlFor="message" className="">Message*</label> */}
                                                            <Field component="textarea" placeholder="Message*" name="message" row="10" aria-labelledby="message" value={values.message}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur} />
                                                            {/* <ErrorMessage name="message" component="div" /> */}
                                                            <div className='errormsg'> {errors.message && touched.message ? (
                                                                <p className="form-error">{errors.message}</p>
                                                            ) : null}</div>
                                                        </div>

                                                        <div className='mb-3 text-dark'>
                                                           
                                                           <div className='btn-box'>
                                                            <Field type="checkbox" name="agree" className="agree mr-5" aria-labelledby="agree"
                                                                value={values.agree}
                                                                onChange={handleChange}
                                                            />
                                                           <span className='agreetbn'> I agree to the <NavLink to='/termsandconditions' onClick={scrollToTop}>terms and conditions</NavLink></span>
                                                           </div>
                                                           
                                                            {/* <label className='checkbox-label' >  I agree to the <NavLink to='/termsandconditions' onClick={scrollToTop}>terms and conditions</NavLink></label> */}
                                                        
                                                            {/* <ErrorMessage name="agree" component="div" /> */}

                                                            <p className='agreetext'>
                                                                Preferred Square is committed to protecting your information. Your information will be used in accordance with the applicable data privacy law, our internal policies and our privacy policy. As Preferred Square is a global organisation, your information may be stored and processed by Preferred Square and its affiliates in countries outside your country of residence, but wherever your information is processed, we will handle it with the same care and respect for your privacy.
                                                            </p>
                                                            <div className='errormsg'> {errors.agree && touched.agree ? (
                                                                <p className="form-error">{errors.agree}</p>
                                                            ) : null}</div>
                                                        </div>


                                                        <div className='text-left mobile-align'>
                                                            {(hide) ? (
                                                                <button 
                                                                disabled
                                                                style={{opacity:"0.5"}}
                                                                type="submit"
                                                            >
                                                                Submit
                                                            </button>
                                                            
                                                            ) :  (
                                                                <button type="submit">Submit
                                                                </button>                         
                                                                )
                                                            }
                                                        </div>
                                                        {/* <div className="form-success text-center mt-5"    dangerouslySetInnerHTML={{ __html: response }}></div>   */}
                                                    </Col>
                                                </Row>
                                               
                                            </Form>
                                        )}
                                    </Formik>

                                {/* </div> */}
                               
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>

                    {/* success popup */}
                    <Modal
                        show={modalShow}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        >
                        {/* <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                            Modal heading
                            </Modal.Title>
                        </Modal.Header> */}
                        <Modal.Body className='text-center p-5'>
                            {/* {resonseMsg} */}
                            <div className="form-success text-center mt-5 d-none"    dangerouslySetInnerHTML={{ __html: response }}></div>                           
                            <h4>Thank you for your interest in Preferred Square! </h4> <p>Our team will reach out to you.</p>
                             <button className='close-btn' onClick={() => setModalShow(false)} >Ok</button>
                        </Modal.Body>
                        {/* <Modal.Footer>
                            
                        </Modal.Footer> */}
                    </Modal>

        </section >

        </>
    )
}

export default Contactbody
