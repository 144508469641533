import React from 'react'
import Menu from '../component/Menu';
import Headerknowledge from '../component/Headerknowledge'
import CareerDes from '../component/CareerDes'
import Getintouch2 from '../component/Getintouch2'
import Footer from '../component/Footer'
import Knowledgebody from '../component/Knowledgebody'
import { Helmet } from 'react-helmet';

const Knowledge = ({setBlank, post_status}) => {
  const paragraph="From strategic insights to emerging trends to innovative frameworks, explore our repository of resources for actionable intelligence and informed decision making."

  return (
    <>
    <Helmet>
      <title>Knowledge</title>
      <meta property="og:title" content="Knowledge" />
      <meta name='og:description' content='Knowledge'/>
      <meta name='keywords' content=''/>
    </Helmet>
        <Menu setBlank={setBlank} />
        <Headerknowledge />
        <CareerDes paragraph={paragraph} />
        <Knowledgebody setBlank={setBlank} post_status={post_status} />
        <Getintouch2 setBlank={setBlank} />
        <Footer setBlank={setBlank} />
    </>
  )
}

export default Knowledge
